/*
-----------------------------------------
Scss
-----------------------------------------
*/

import '~/sass/main.scss';

/*
-----------------------------------------
Vendors
-----------------------------------------
*/

import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import 'popper.js';
import 'bootstrap';
import Swiper from 'swiper';
import Parallax from 'parallax-js';
import html2canvas from 'html2canvas';

/*
-----------------------------------------
Init
-----------------------------------------
*/

$(function onReady() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr("content")
        }
    });
    /*
    -----------------------------------------
    Share image
    -----------------------------------------
    */

    $('#share-btn').on('click', function(e) {
        e.preventDefault();
        $(this).attr("disabled", true);
        html2canvas(document.querySelector(".share")).then(canvas => {
            var dataURL = canvas.toDataURL();
            $.ajax({
              type: "POST",
              url: "/facebook/share",
              data: {
                 share_img: dataURL
              }
            }).done(function(link) {
              window.location.replace("https://www.facebook.com/sharer/sharer.php?u="+link);
            });
            // document.body.appendChild(canvas)
        });
    });

    /*
    -----------------------------------------
    Object-fit Polyfill -- https://github.com/bfred-it/object-fit-images
    -----------------------------------------
    */

    objectFitImages();

    /*
    -----------------------------------------
    Limit Gallery Checkbox selection
    -----------------------------------------
    */

    var limit = 3;

    $('.gallery-checkbox').on('change', function() {
        if($(".gallery-checkbox:checked").length > limit) {
            this.checked = false;
        }
    });

    /*
    -----------------------------------------
    Instructions Video
    -----------------------------------------
    */

    var InstructionsVideoURL = 'https://www.youtube.com/embed/S94Z9jb3ECI?autoplay=1&rel=0';

    $('#instructions-modal').on('hidden.bs.modal', function () {
        $('#instructions-video').attr('src', '');
    })

    $('#instructions-modal').on('shown.bs.modal', function () {
        $('#instructions-video').attr('src', InstructionsVideoURL);
    })

    /*
    -----------------------------------------
    Parallax -- https://github.com/wagerfield/parallax
    -----------------------------------------
    */

    var scene = $('#parallax');

    if(scene.length){
        new Parallax(scene.get(0));
    }

    /*
    -----------------------------------------
    Swiper -- http://idangero.us/swiper/api/
    -----------------------------------------
    */

    // Products Slider
    new Swiper('.products-slider', {
        slidesPerView: 1,
        loop: true,
        navigation: {
            nextEl: $(this).find(".products-next")[0],
            prevEl: $(this).find(".products-prev")[0],
        },
        on: {
            slideChange: function(){
                var currentIndex = this.activeIndex,
                    currentSlide = this.slides[currentIndex];

                $('#product-id').val($(currentSlide).data("product"));
            },
        }
    });

});